import React, {useState, useEffect} from "react";

import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import CopyToClipboardButton from "../../../../components/Buttons/CopyToClipboardButton";

import TableContainer from "../TableContainer.js";

import X3Image from './Images/X3.png'
import X5Image from './Images/X5.png'
import { useMemo } from "react";

function LoanCalculator({userid}) {

    const [loanAmount, setLoanAmount] = useState(0)
    const [termOption, setTermOption] = useState(null)
    const [multiplierOption, setMultiplierOption] = useState(null)

    const [userAmount, setUserAmount] = useState(0)
    const [payoutAmount, setPayoutAmount] = useState(0)
    const [poolBalanceAmount, setpoolBalanceAmount] = useState(null)
    const [otpPin, setOTPPin] = useState(null)

    
    const [showSpinner, setShowSpinner] = useState(false)
    const [mainLoader, setMainLoader] = useState(true)

    const [currency, setCurrency] = useState(null)

    const [step, setStep] = useState(1)
    const [loanSettings, setLoanSettings] = useState({})

    React.useEffect(() => {
        const fetchData = async () => {
            await getLoanOptions();
        };
    
        fetchData();
    }, [userid]); 

    const getLoanOptions = async() => {
        var loanObj = {
            productid: '1'
        }
        await axios.post(process.env.REACT_APP_BASE + "api/ltv-loan-options",loanObj).then((res)=>{
           
            if(res.data.status == 200)
            {
                var newLoanInfo = res.data.loanInfo;
            
                setLoanSettings(newLoanInfo);
                setFromCoins(JSON.parse(newLoanInfo.from_coin))
                setToCoins(JSON.parse(newLoanInfo.to_coin))
                setLTVOptions(JSON.parse(newLoanInfo.ltv_options))
                setMainLoader(false)
            }
            
        })
    }

    const [selectedOption, setSelectedOption] = useState(null);

    const handleOptionSelect = async(multiplier, term) => {
        setMultiplierOption(multiplier)
        setTermOption(term);
      
        await calculateProjectedAmount(multiplier, term, userAmount)
    };

    const calculateProjectedAmount = async(multiplier, term, amount) => {
        
        setPayoutAmount(amount * multiplier)

        var poolPercentage = (loanSettings.percentageToPool + loanSettings.percentageToCommision) / 100;
        var amountToPoolOriginal = amount * poolPercentage;
        setpoolBalanceAmount((amountToPoolOriginal) * multiplier)

        var loanPercentage = loanSettings.percentageToLoan / 100;
        var amountToLoanOriginal = amount * loanPercentage;
        setLoanAmount((amountToLoanOriginal) * multiplier)
    }

    const onInvestmentAmountChange = async(amount) => {
        setUserAmount(amount)
        await calculateProjectedAmount(multiplierOption, termOption, amount)
    }

    const nextStep = async() => {
        setMainLoader(true)
        var newStep = step + 1;
      
        if(newStep === 2)
        {
            await getLoanQuote();
            sendOTP();
        }
        setStep(step + 1)
        setMainLoader(false)
    }

    const prevStep = async() => {
        if(step > 1)
            setStep(step - 1)
    }

    const sendOTP = async () => {

        var otpObj = {
            userid: userid
          }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp",otpObj).then((res)=>{
 
            if(res.data.status == 200)
            {
            
                toast.success('OTP Pin Successfully Sent!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

            }else{
            
                toast.error('Unable To send OTP!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
            }
            
        })

    }

    const handleAccept = async () => {

        setShowSpinner(true)
    

        if(!otpPin)
        {
            toast.error('Please Enter Valid OTP Pin', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });
                setShowSpinner(false)

                return false;
        }
        

        if(!currency || currency === '')
        {
            toast.error('Please Select a Collateral Currency', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });

                setShowSpinner(false)

                return false;
        }
 

            var otpObj = {
                userid: userid,
                otp: otpPin
            }
            
            const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp",otpObj).then(async (res)=>{
      
                if(res.data.status !== 200)
                {
                    
                    toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                        });

                        await sendOTP();

                        setShowSpinner(false)

                    return false;
                }else{
             
                
                    return true;
                }
            });

            if(!checkOtp)
                return false;

            // Let check their balance first
            // var balanceObject = {
            //     userid: userid,
            //     currency: currency,
            //   }
          
            //   var checkBalance = await axios.post(process.env.REACT_APP_API_BASE_URL + "/user-balance", balanceObject).then(async res => {
          
            //     if(res.data.status === 200)
            //     {
            //         if (parseFloat(res.data.total) < userAmount) {
            //             toast.error('Insufficient Balance!', {
            //               position: "top-right",
            //               autoClose: 5000,
            //               hideProgressBar: false,
            //               closeOnClick: true,
            //               pauseOnHover: true,
            //               draggable: true,
            //               progress: undefined,
            //               theme: "dark",
            //             });
                
            //             setShowSpinner(false)
                
            //             return false;
            //           } else {
            //             return true
            //           }
            //     }else{
            //         return false;
            //     }
               
          
            //   })
          
            //   if (!checkBalance)
            //     return false;
   
            // Create the loan
            // userid, term, multiplier, currency, userAmount, loanAmount, payoutAmount, poolBalanceAmount
            //userid, collat_coin, loan_coin, exchange_rate, collateral_percentage, interest_rate, period, liquidation_amount, loan_amount, collateral_amount
            var newLoanAmount = userAmount * (70 / 100);
            var newPoolBalanceAmount = userAmount * (30 / 100);
            const loanTransactionObj = {
                userid: userid,
                collat_coin: selectedFromCoin,
                loan_coin: selectedCoin,
                exchange_rate: exchangeRate,
                collateral_percentage: selectedLTVOption,
                interest_rate: interestRate,
                period: loanPeriod,
                liquidation_amount: liquidationPrice,
                loan_amount: loanAmount,
                collateral_amount: userAmount,
                company_proceeds: autoRenewBalance
            }
            await axios.post(process.env.REACT_APP_BASE + "api/create-new-ltv-loan",loanTransactionObj).then(async (res)=>{
             
                if(res.data.status === 200)
                {
                 
                        toast.success('LTV Loan Created Successfully!', {
                          position: "top-right",
                          autoClose: 5000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          theme: "dark",
                        });

                        await saveUserActivity(userid, "User Added Loan Application")

                        setTimeout(() => {window.location.reload(); }, 2000);

                }else{

                    setShowSpinner(false)
                    toast.error('Unable To Create Product Sale!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                      });

                      return false;
                }
            });


        }


        const saveUserActivity = async(userid, activity) => {

            var activityData = {
                userid: userid,
                activity: activity
            }
    
    
            await axios.post(`${process.env.REACT_APP_API_BASE_URL}/save-user-activity`,activityData).then(async (res)=>{
    
            })
          }

          const [selectedCoin, setSelectedCoin] = useState('');
          const [selectedFromCoin, setSelectedFromCoin] = useState('');
          const [selectedLTVOption, setSelectedLTVOption] = useState('');
          const [defaultPeriod, setDefaultPeriod] = useState(12);
          const [exchangeRate, setExchangeRate] = useState(0);

          const [fromCoins, setFromCoins] = useState(null);
          const [toCoins, setToCoins] = useState(null);
          const [ltvOptions, setLTVOptions] = useState(null);
          const [displayGraph, setDisplayGraph] = useState(false);

          const handleCoinChange = (e) => {
            setSelectedCoin(e.target.value);
            // Save the from currency (e.target.value) as needed
            // For demonstration, just logging to console
          
        };

        
        const handleFromCoinChange = (e) => {
            setSelectedFromCoin(e.target.value);
            // Save the from currency (e.target.value) as needed
            // For demonstration, just logging to console
      
            setCurrency(e.target.value);
        };

        const handleSelectedLTVChange = async(e) => {
            setSelectedLTVOption(e.target.value);
        }

        var paymentOptions = {
            '50': {
                period: 36,
                interestRate: 2.8
            },
            '35': {
                period: 36,
                interestRate: 2.1
            },
            '20': {
                period: 36,
                interestRate: 1.55
            }
        }

        var paymentOptions1 = {
            '50': {
                period: 60,
                interestRate: 2.05
            },
            '35': {
                period: 60,
                interestRate: 1.6
            },
            '20': {
                period: 60,
                interestRate: 1.3
            }
        }

        const [data, setData] = useState([])

        const columns = useMemo(
            () => [
                {
                    Header: 'Month',
                    accessor: 'month',
                },
                {
                    Header: 'Starting Balance',
                    accessor: 'starting_balance'
                },
                {
                    Header: 'Proceeds',
                    accessor: 'proceeds'
                },
                {
                    Header: 'End Balance',
                    accessor: 'end_balance'
                },
            ],
            []
        );

        const [interestRate, setInterestRate] = useState(10);
        const [repaymentOption, setRepaymentOption] = useState('');
        const [liquidationPrice, setLiquidationPrice] = useState(0);
        const [loanPeriod, setLoanPeriod] = useState(0);
        const [CollateralAmount, setCollateralAmount] = useState(0);
        const [paymentOptionToUse, setPaymentOptionToUse] = useState(paymentOptions);

        const [calculating, setCalculating] = useState(false);
        const [displayLoader, setDisplayLoader] = useState(false);

        const [companyProceeds, setCompanyProceeds] = useState(false);


        const getLoanQuote = async() => {
            setCalculating(true)

            if(!selectedCoin || selectedCoin == '' || !selectedLTVOption || selectedLTVOption == '' || !selectedFromCoin || selectedFromCoin == ''
            || !defaultPeriod || defaultPeriod == '' || userAmount <= 0)
            {
                toast.error('Please ensure all fields are filled in.', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    });

                    setCalculating(false)

                    return;
            }
            var loanCalculatorObj = {
                userid: userid,
                productid: 1,
                loan_coin: selectedCoin,
                ltv_option: selectedLTVOption,
                collateral_coin: selectedFromCoin,
                period: defaultPeriod,
                collatAmount: userAmount
            }
         
            const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/loan-to-value-calculator",loanCalculatorObj).then(async (res2)=>{
              
                if(res2.data.status === 200)
                {
                    
                    var ltvToNumber = parseInt(selectedLTVOption)
                    var newpaymentOption = paymentOptionToUse[ltvToNumber]
         
                    setLoanPeriod(newpaymentOption.period)
                    
                    setLiquidationPrice(res2.data.quote.liquidationPrice)
                    setExchangeRate(res2.data.quote.exchange_rate)
                    setLoanAmount(parseFloat(res2.data.quote.loanAmount).toFixed(2))
                    setCollateralAmount(parseFloat(res2.data.quote.collateralAmount).toFixed(2))
                    var startingAmount = parseFloat(res2.data.quote.collateralAmount).toFixed(2) - res2.data.quote.loanAmount

                    const FVAmount = await calculateFutureValue(startingAmount, newpaymentOption.period, newpaymentOption.interestRate);
                   // const FVAmount = await calculateFutureValue(startingAmount,res2.data.quote.collateralAmount ,newpaymentOption.period,0,  10);
                    setData(FVAmount);
                    setDisplayGraph(true);
                   
                }

                setCalculating(false)
            });
        }
    

        const onPeriodChange = async(period) => {
         
            if(period === "36")
            {
             
                setPaymentOptionToUse(paymentOptions)
            }else{
             
                setPaymentOptionToUse(paymentOptions1)
            }
        }
    

           // Function to calculate future value for each period * Back TTo
    const calculateFutureValue = async (userLoanAmount, PeriodNumber, newInterestRate) => {
        // Example input values
        const numberOfPeriods = PeriodNumber; // N: Number of periods (months)
        const startingAmount = parseFloat(userLoanAmount) // PV: Starting Amount
        const monthlyInterestRate = newInterestRate; // I/Y: Annual interest rate (as a decimal, so 5% = 0.05)
        const periodicDeposit = 0; // PMT: Periodic deposit

        let futureValueData = [];
        let currentBalance = parseFloat(startingAmount);
        const yearlyInterestRate = 10; // Representing the 5% annual interest rate

        for (let month = 1; month <= numberOfPeriods; month++) {
            let interestForMonth = currentBalance * (monthlyInterestRate / 100);
            let endBalance = currentBalance + interestForMonth + periodicDeposit;
    
            // Every 12 months, calculate 5% on the end balance and subtract it
            if (month % 12 === 0) {
                let yearlyFee = endBalance * (yearlyInterestRate / 100);
                endBalance -= yearlyFee; // Subtract the yearly fee from the end balance
            }
    
            futureValueData.push({
                month: month,
                starting_balance: currentBalance.toFixed(2),
                proceeds: interestForMonth.toFixed(2),
                end_balance: endBalance.toFixed(2),
            });
    
            currentBalance = endBalance; // Update currentBalance for the next iteration
        }

        // Reverse the array to start with the last month
        return futureValueData.reverse();
    };

    // const calculateMonthlyRate = (loan_Amount, collatAmount, months=36) => {
    //   let rate = 0.01; // Starting guess rate
    //   let calculatedEndValue = loan_Amount;
      
    //   while (rate < 1) { // Incrementing rate in 0.01 (1%) steps to find a suitable rate
    //     calculatedEndValue = loan_Amount; // Reset calculatedEndValue to start value for each rate attempt
        
    //     for (let i = 0; i < months; i++) {
    //       calculatedEndValue += calculatedEndValue * rate;
    //     }
        
    //     if (calculatedEndValue >= collatAmount) break; // Found a rate that achieves or exceeds the end value
        
    //     rate += 0.01; // Increment rate and try again
    //   }
      
    //   return rate;
    // };

    // const calculateFutureValue = async (startingAmount, endAmount, numberOfPeriods, periodicDeposit, yearlyInterestRate) => {
    //     console.log("Parameters:", startingAmount, endAmount, numberOfPeriods, periodicDeposit, yearlyInterestRate);
    //     let futureValueData = [];
    //     let currentBalance = parseFloat(startingAmount);
    //     const totalGrowth = endAmount - startingAmount - (periodicDeposit * numberOfPeriods);
    //     const monthlyGrowth = totalGrowth / numberOfPeriods;
        
    //     // Check if the initial calculation results are as expected
    //     console.log("Initial Calculations: ", totalGrowth, monthlyGrowth);
    
    //     for (let month = 1; month <= numberOfPeriods; month++) {
    //         let interestForMonth = (month % 12 === 0) ? 0 : monthlyGrowth; // Simulating monthly growth
    //         let endBalance = currentBalance + interestForMonth + periodicDeposit;
            
    //         if (month % 12 === 0) {
    //             let yearlyFee = endBalance * (yearlyInterestRate / 100);
    //             endBalance -= yearlyFee; // Apply yearly fee
    //         }
            
    //         futureValueData.push({
    //             month: month,
    //             starting_balance: currentBalance.toFixed(2),
    //             proceeds: interestForMonth.toFixed(2),
    //             end_balance: endBalance.toFixed(2),
    //         });
            
    //         currentBalance = endBalance; // Prepare for the next iteration
    //     }
        
    //     // Optionally reverse the data
    //     futureValueData.reverse();
    //     console.log("End Future data is:",futureValueData)
    //     return futureValueData;
    // };
    
    
    
    const[autoRenewBalance, setAutoRenewBalance] =  React.useState(false);
    const handleWalletBalanceAuto = (value) => {
        var isTrueSet = (value === 'true');
 
        setAutoRenewBalance(isTrueSet)
    }
        

    return (
        <div className="container-md px-4 pl-6">
                    <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            />
            <div className="w-full">
                <form     className="shadow-md rounded px-8 pt-6 pb-8 mb-4 hilighted-box" 
                    onSubmit={(e) => { e.preventDefault(); }}>
                    <h1 className="title text-white text-center mb-2">LTV Loan Calculator</h1>
                    {!mainLoader?
                    
                    step === 1 ?
                    <div>
                        {/* <div className="mb-4">
                            <label className="block text-white-700 text-sm font-bold mb-2" htmlFor="investmentAmount">
                                Investment Amount
                            </label>
                            <input type="number" step="0.01" min="0" value={userAmount} onChange={(e) => { onInvestmentAmountChange(e.target.value); }} className="form-control shadow appearance-none border rounded w-full form-no-background py-2 px-3 text-white-700 leading-tight focus:outline-none form-no-background focus:shadow-outline" />
                        </div> */}

          
                            {/* New section for input fields */}
                            <div className="flex justify-between space-x-4">
                                {/* Left Column */}
                                <div className="flex-1">
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Amount I Have</label>
                                        <input type="number" value={userAmount} onChange={(e) => { onInvestmentAmountChange(e.target.value); }} className="shadow appearance-none border rounded w-full form-no-background py-2 px-3 leading-tight focus:outline-none" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Coin I Have</label>
                                        <select className="select-transparent shadow appearance-none border rounded bg-gray-100 w-full py-2 px-3  focus:outline-none" value={selectedFromCoin} onChange={handleFromCoinChange}>
                                            <option style={{background: "transparent"}} value="">Select Coin</option>
                                            {toCoins && toCoins.map((coin, index) => (
                                                <option key={index} value={coin}>{coin.toUpperCase()}</option>
                                            ))}
                                        </select>
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Loan Coin</label>
                                        <select className="select-transparent shadow appearance-none border rounded bg-gray-100 w-full py-2 px-3  focus:outline-none" value={selectedCoin} onChange={handleCoinChange}>
                                            <option value="">Select Coin</option>
                                            {fromCoins.map((coin, index) => (
                                                <option key={index} value={coin}>{coin.toUpperCase()}</option>
                                            ))}
                                        </select>
                                    </div>
                        
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">LTV Option</label>
                                        <select className="select-transparent shadow appearance-none border rounded bg-gray-100 w-full py-2 px-3  focus:outline-none" value={selectedLTVOption} onChange={handleSelectedLTVChange}>
                                            <option value="">Select LTV Option</option>
                                            {ltvOptions && Object.entries(ltvOptions).map(([key, value]) => (
                                                <option key={key} value={key}>{value}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Loan Term</label>
               
                                        <select onChangeCapture={(e) => {onPeriodChange(e.target.value)}} className="select-transparent shadow appearance-none border rounded bg-gray-100 w-full py-2 px-3  focus:outline-none"  >
                                            <option value="">Select Loan Term</option>
                                            {/* <option value="36">36 Months</option> */}
                                            <option value="60">60 Months</option>
                                        </select>
                                    </div>

                                </div>

                                {/* Right Column */}
                                <div className="flex-1">
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Interest Rate PA</label>
                                        <input disabled value={"10%"} type="text" className="shadow appearance-none border bg-gray-200 rounded w-full  py-2 px-3 leading-tight focus:outline-none text-black" />
                                    </div>
                                    {/* <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Repayment Option</label>
                                        <input disabled type="text" className="shadow appearance-none border rounded w-full  py-2 px-3 leading-tight focus:outline-none bg-gray-200 text-black" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Monthly Payment</label>
                                        <input disabled type="number" className="shadow appearance-none border rounded w-full py-2 px-3 leading-tight focus:outline-none bg-gray-200 text-black" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Final Payment</label>
                                        <input disabled type="number" className="shadow appearance-none border rounded w-full  py-2 px-3 leading-tight focus:outline-none bg-gray-200 text-black" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Maturity Date</label>
                                        <input disabled type="date" className="shadow appearance-none border rounded w-full  py-2 px-3 leading-tight focus:outline-none bg-gray-200 text-black" />
                                    </div> */}
                                    {/* <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Liquidation Price</label>
                                        <input disabled type="text" value={liquidationPrice} className="shadow appearance-none border rounded w-full  py-2 px-3 leading-tight focus:outline-none bg-gray-200 text-black" />
                                    </div> */}
                   
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Loan Amount</label>
                                        <input disabled type="number" value={loanAmount} className="shadow appearance-none border rounded w-full  py-2 px-3 leading-tight focus:outline-none bg-gray-200 text-black" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Collateral Amount in {selectedCoin}</label>
                                        <input disabled type="number" value={CollateralAmount} className="shadow appearance-none border rounded w-full  py-2 px-3 leading-tight focus:outline-none bg-gray-200 text-black" />
                                    </div>
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Liquidation Price</label>
                                        <input disabled type="number" value={liquidationPrice.toFixed(2)} className="shadow appearance-none border rounded w-full  py-2 px-3 leading-tight focus:outline-none bg-gray-200 text-black" />
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="mb-4">
                                    <div className="flex items-center">  
                                        <button disabled={calculating}  className="px-4 rounded-r c1-primary-btn text-white focus:outline-none focus:shadow-outline" type="button" onClick={() => getLoanQuote()}>
                                            {calculating ? 'Loading...' : "Calculate"}
                                        </button>
                                    </div>
                                </div>

                            {displayGraph?
                        <>
       

                                <div className="mb-4 mt-3 loanCalculatorContainer">
                                <TableContainer
                                    columns={columns}
                                    data={data}
                                    isGlobalFilter={true}
                                    isAddOptions={true}
                                    customPageSize={10}
                                    className="custom-header-css"
                                />
                                </div>

                            <div className="flex items-center justify-between">
                                <button onClick={() => {nextStep()}} className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    Invest Now
                                </button>
                            </div>
            
                            </>
                                            : ''}
                    </div>
                    : step === 2 ?
                        <div>
       
                            
                            <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Amount I Have</label>
                                        <input type="number" disabled value={userAmount} className="shadow appearance-none border rounded w-full form-no-background py-2 px-3 leading-tight focus:outline-none" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Collateral Coin</label>
                                        <input type="text" disabled value={selectedFromCoin} className="shadow appearance-none border rounded w-full form-no-background py-2 px-3 leading-tight focus:outline-none" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Loan Coin</label>
                                        <input type="text" disabled value={selectedCoin} className="shadow appearance-none border rounded w-full form-no-background py-2 px-3 leading-tight focus:outline-none" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Loan Amount</label>
                                        <input type="number" disabled value={loanAmount} className="shadow appearance-none border rounded w-full form-no-background py-2 px-3 leading-tight focus:outline-none" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Liquidation Price</label>
                                        <input disabled type="number" value={liquidationPrice.toFixed(2)} className="shadow appearance-none border rounded w-full  py-2 px-3 leading-tight focus:outline-none" />
                                    </div>

                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Loan Term</label>
                                        <input type="text" disabled value={loanPeriod + "Months"} className="shadow appearance-none border rounded w-full form-no-background py-2 px-3 leading-tight focus:outline-none" />
                                    </div>

                                    {loanPeriod === 60 ?
                                    
                                    <div className="mb-4">
                                        <label className="block text-sm font-bold mb-2">Allow C1 WEALTH To Generate Monthly Proceeds to Pay Back Loan</label>
                                                <input onClick={(e) => handleWalletBalanceAuto(e.target.value)} type="radio" checked={autoRenewBalance === true}  className="btn-check" name="autorenewbalance-radio"  value={true} id="yesautobalance" />
                                                <label className={'c1-primary-radio mr-8' + (autoRenewBalance === true ? ' c1-primary-btn-active' : '')} htmlFor="yesautobalance">Yes</label>
                                                <input onClick={(e) => handleWalletBalanceAuto(e.target.value)} checked={autoRenewBalance === false}  type="radio" className="btn-check" name="autorenewbalance-radio" value={false} id="noautobalance"/>
                                                <label className={'c1-primary-radio mr-8' + (autoRenewBalance === false ? ' c1-primary-btn-active' : '')} htmlFor="noautobalance">No</label>      
                                            </div>
                                            : ''}
                                    

                            <div className="mb-4">
                            
                            <label className="block text-white-700 text-sm font-bold mb-2" for="password">
                                OTP PIN 
                            </label>
                            <input type="text" value={otpPin} onChange={(e) => {setOTPPin(e.target.value)}} className="form-control shadow appearance-none border rounded w-full form-no-background py-2 px-3 text-white-700 leading-tight focus:outline-none focus:shadow-outline form-no-background" />
                            </div>
                                


                            {!showSpinner ?
                            <div className="flex items-center justify-between">
                                <button onClick={() => {handleAccept()}} className="c1-primary-btn text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline" type="button">
                                    Invest Now
                                </button>
                                <Button style={{    
                                    background: "#040E18",
                                    borderRadius: "70px",
                                    border: "2px solid #fa0a0a",
                                    float: "right",
                                    color: "#fff"}} className='c1-primary-btn' onClick={prevStep}>Back</Button>
                            </div>
                            : <p>Loading...</p>
                             }
                        </div>        
                    : ''
                : <div class="loader-c1fs"></div>}
                </form>
            </div>
        </div>
    )
}

export default LoanCalculator