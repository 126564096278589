import React, { useState, useEffect } from "react";

import { UserContext } from "../../UserContext";
import axios from 'axios'

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import TransactionModal from '../../components/Modals/TransactionModal'
import TourGuide from "../../components/Modals/Tours";
import CopyToClipboardButton from "../../components/Buttons/CopyToClipboardButton";
import LoanTransactionModal from "../../components/Modals/Loans/LoanTransactionModal";

function Deposit() {

    const [modalOTP, setModalOTP] = React.useState({ otp: '', from: '', amount: '', type: 'deposit', reference: '' });

    const [modalDisplay, setModalDisplay] = React.useState(false);
    const [loanModalDisplay, setLoanModalDisplay] = React.useState(false);
    

    const { auth, user } = React.useContext(UserContext)
    const [userid, setUserid] = React.useState('');
    const [canContinue, setCanContinue] = React.useState(true);
    const [countryCurrency, setCountryCurrency] = React.useState('ZAR');
    const [showSpiner, setShowSpinner] = React.useState(false);
    const [userDetails, setUserDetails] = React.useState(null);

    const [tourSteps, setTourSteps] = React.useState([]);
    const [allowTourClose, setAllowTourClose] = React.useState(false);


    React.useEffect(() => {
        // window.location.href="/dashboard"
        if (auth != null) {
            if (!auth) {
                window.location.href = "/login"
            } else if (auth && user) {
                getUserDetails(user.user.userid)

                setUserDetails(user.user)

                setUserid(user.user.userid)
                saveUserActivity(user.user.userid, "Viewed Deposit Page")

                getFirstDeposit(user.user.userid)
            }
        }


    }, [auth])

    const getUserDetails = async (userid) => {
        var userObj = {
            userid: userid
        }

        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/get-user-info`, userObj).then(async (res) => {
            if (res.data.status == 200) {
                var mainString = res.data.info.country
                if (mainString && mainString.includes("Namibia")) {
                    setCountryCurrency('NAD')
                }
            }
        });
    }

    const getFirstDeposit = async (userid) => {

        var depositObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/confirm-any-deposits", depositObj).then(async (res) => {

            if (res.data.status === 400) {
                var tourObj = [

                    {
                        element: '.deposit-explenation',
                        popover:
                        {
                            title: 'Deposit Guidelines', description:
                                "Before proceeding with your initial deposit, kindly review all relevant information regarding wallet and bank details. Ensure you have thoroughly read through the details before making your payment."
                        }
                    },

                    {
                        element: '.deposit-button',
                        popover:
                        {
                            title: 'Ready To Deposit', description: 'After reviewing all information and completing the payment, click on the "Deposit Now" button to initiate your first deposit.'
                        }
                    },

                ]

                setTourSteps(tourObj)
                setAllowTourClose(true)
            }
        });


    }

    const saveUserActivity = async (userid, activity) => {

        var activityData = {
            userid: userid,
            activity: activity
        }


        await axios.post(`${process.env.REACT_APP_API_BASE_URL}/save-user-activity`, activityData).then(async (res) => {

        })
    }

    const openTransactionModal = () => {
        setModalDisplay(true)
    }

    const closeModal = (event, reason) => {

        if (reason && reason == "backdropClick")
            return;
        setModalDisplay(false)
    }

    const currencyConvert = {
        'usdt': '1',
        'zar': '2',
        'btc': '3',
    }

    const handleAccept = async () => {

        setShowSpinner(true)


        if (modalOTP['amount'] <= 0) {
            toast.error('Please Enter Valid Amount', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setShowSpinner(false)

            return false;
        }


        if (modalOTP['reference'] === '') {
            toast.error('Please Enter Valid Reference or TXID', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setShowSpinner(false)

            return false;
        }

        if (canContinue) {

            setCanContinue(false)
            var otpObj = {
                userid: userid,
                otp: modalOTP['otp']
            }

            const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp", otpObj).then(async (res) => {

                if (res.data.status !== 200) {

                    toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });

                    await sendOTP();

                    setCanContinue(true)
                    setShowSpinner(false)

                    return false;
                } else {


                    return true;
                }
            });

            if (!checkOtp)
                return false;

            const depositObject = {
                userid: userid,
                txid: modalOTP['reference'],
                amount: modalOTP['amount'],
                tran_cat: '1',
                currency: currencyConvert[modalOTP['from']],
                type: "Adding Deposit"
            }


            var createTransaction = await axios.post(process.env.REACT_APP_BASE + "api/create-transaction", depositObject).then(async res => {



                if (res.data.status == 200) {
                    toast.success('Deposit Created Successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });


                    var zarAmount = modalOTP['from'] == 'zar' ? 'ZAR ' + modalOTP['amount'] : modalOTP['from'] == 'nad' ? 'NAD ' + modalOTP['amount'] : "N/A";

                    var emailObj = {
                        userid: userid,
                        currency: modalOTP['from'].toUpperCase(),
                        amount: modalOTP['amount'],
                        zar_amount: zarAmount
                        // idnumber, currency, amount, zar_amount
                    }


                    var sendDeositEmail = await axios.post(process.env.REACT_APP_BASE + "api/send-deposit-contract", emailObj).then(async res => { });

                    setTimeout(() => { window.location.reload(); }, 2000);


                } else {
                    toast.error('Unable To Create Deposit', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });

                    setShowSpinner(false)

                    setCanContinue(true)
                }

            });

            await saveUserActivity(userid, "User Added deposit")
        } else {

            setShowSpinner(false)
        }

        // setModalDisplay(false)
    }

    const sendOTP = async () => {

        var otpObj = {
            userid: userid
        }

        await axios.post(process.env.REACT_APP_BASE + "api/send-transaction-otp", otpObj).then((res) => {

            if (res.data.status == 200) {

                toast.success('OTP Pin Successfully Sent!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });

            } else {

                toast.error('Unable To send OTP!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }

        })

    }


    // Loans
    const openLoanTransactionModal = () => {
        setLoanModalDisplay(true)
    }

    const closeLoanModal = (event, reason) => {

        if (reason && reason == "backdropClick")
            return;
            setLoanModalDisplay(false)
    }

    const currencyLoanConvert = {
        'usdt': '5',
        'zar': '6',
        'btc': '3',
    }

    const handleLoanAccept = async() => {

        setShowSpinner(true)


        if (modalOTP['amount'] <= 0) {
            toast.error('Please Enter Valid Amount', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });
            setShowSpinner(false)

            return false;
        }


        if (modalOTP['reference'] === '') {
            toast.error('Please Enter Valid Reference or TXID', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
            });

            setShowSpinner(false)

            return false;
        }

        if (canContinue) {

            setCanContinue(false)
            var otpObj = {
                userid: userid,
                otp: modalOTP['otp']
            }

            const checkOtp = await axios.post(process.env.REACT_APP_BASE + "api/confirm-otp", otpObj).then(async (res) => {

                if (res.data.status !== 200) {

                    toast.error("Incorrect OTP, We Have Sent A New OPT!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });

                    await sendOTP();

                    setCanContinue(true)
                    setShowSpinner(false)

                    return false;
                } else {


                    return true;
                }
            });

            if (!checkOtp)
                return false;

            const depositObject = {
                userid: userid,
                txid: modalOTP['reference'],
                amount: modalOTP['amount'],
                tran_cat: '1',
                currency: currencyLoanConvert[modalOTP['from']],
                type: "Adding Deposit"
            }

            // console.log("This is what we are sending through: ", depositObject, modalOTP['from'])
            // setShowSpinner(false)

            // setCanContinue(true)
            // return


            var createTransaction = await axios.post(process.env.REACT_APP_BASE + "api/create-loan-transaction", depositObject).then(async res => {



                if (res.data.status == 200) {
                    toast.success('Deposit Created Successfully!', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });


                    var zarAmount = modalOTP['from'] == 'zar' ? 'ZAR ' + modalOTP['amount'] : modalOTP['from'] == 'nad' ? 'NAD ' + modalOTP['amount'] : "N/A";

                    var emailObj = {
                        userid: userid,
                        currency: modalOTP['from'].toUpperCase(),
                        amount: modalOTP['amount'],
                        zar_amount: zarAmount
                        // idnumber, currency, amount, zar_amount
                    }


                    var sendDeositEmail = await axios.post(process.env.REACT_APP_BASE + "api/send-deposit-contract", emailObj).then(async res => { });

                    setTimeout(() => { window.location.reload(); }, 2000);


                } else {
                    toast.error('Unable To Create Deposit', {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });

                    setShowSpinner(false)

                    setCanContinue(true)
                }

            });

            await saveUserActivity(userid, "User Added deposit")
        } else {

            setShowSpinner(false)
        }

        // setModalDisplay(false)
    }



    return (
        <div className="container mx-auto px-2 pt-8 pb-8">

            <TourGuide steps={tourSteps} allowClose={allowTourClose} />

            {modalDisplay ?
                <TransactionModal modalDisplay={modalDisplay} modalOTP={modalOTP} closeModal={closeModal} handleAccept={handleAccept} showSpiner={showSpiner} title="Deposit" />
                : ''
            }

            {loanModalDisplay ?
                <LoanTransactionModal modalDisplay={loanModalDisplay} modalOTP={modalOTP} closeModal={closeLoanModal} handleAccept={handleLoanAccept} showSpiner={showSpiner} title="Loan Deposit" />
                : ''
            }
            <h1 className="mb-6 mt-6 text-1xl font-extrabold leading-none tracking-tight md:text-1xl lg:text-3xl text-white">Deposit</h1>

            <div className="container-md mx-auto px-4">


                <div className="container-md px-4 primary-br-2">

                    <div className="deposit-explenation">
                        <ol type="1">
                            <li>Deposits can be made in the following currencies:
                                <ul className='ml-3' style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>{countryCurrency}</li>
                                    <li style={{ listStyleType: "disc" }}>USDT TRC-20 (TRON)</li>
                                    <li style={{ listStyleType: "disc" }}>USDT ERC-20</li>
                                    <li style={{ listStyleType: "disc" }}>Bitcoin</li>
                                </ul>
                            </li>
                            <li>ZAR deposits can be made to one of the following bank accounts and proof of payment must be sent to pop@c1wealth.co.za:
                                <ul className='ml-3' style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>Account Holder: C1 Wealth Management (Pty) Ltd</li>
                                    <li style={{ listStyleType: "disc" }}>Bank Name: Capitec Business</li>
                                    <li style={{ listStyleType: "disc" }}>Account Type: Current Account</li>
                                    <li style={{ listStyleType: "disc" }}>Account Number: 1051271495</li>
                                    <li style={{ listStyleType: "disc" }}>Branch Code: 450105</li>
                                    <li style={{ listStyleType: "disc" }}>Branch Name: Sandton</li>
                                    <li style={{ listStyleType: "disc" }}>Swift Code: CABLZAJJ</li>
                                </ul>
                                OR
                                <ul className='ml-3' style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>Account Holder: C1 Wealth Management (Pty) Ltd</li>
                                    <li style={{ listStyleType: "disc" }}>Bank Name: Standard Bank</li>
                                    <li style={{ listStyleType: "disc" }}>Account Type: BIZLAUNCH</li>
                                    <li style={{ listStyleType: "disc" }}>Account Number: 10208612953</li>
                                    <li style={{ listStyleType: "disc" }}>Branch Code: 000205</li>
                                    <li style={{ listStyleType: "disc" }}>Branch Name: Johannesburg</li>
                                    <li style={{ listStyleType: "disc" }}>Swift code: SBZA ZA JJ</li>
                                </ul>
                                OR
                                <p className="text-red-500">(Namibia Only)</p>
                                <ul className='ml-3' style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>Account Holder: C ONE FINANCIAL SERVICES</li>
                                    <li style={{ listStyleType: "disc" }}>Bank Name: FNB</li>
                                    <li style={{ listStyleType: "disc" }}>Account Type: Enterprise Business Account</li>
                                    <li style={{ listStyleType: "disc" }}>Account Number: 64282301123</li>
                                    <li style={{ listStyleType: "disc" }}>Branch Code: 280172</li>
                                    <li style={{ listStyleType: "disc" }}>Branch Name: Windhoek</li>
                                    <li style={{ listStyleType: "disc" }}>Swift Code: FIRNNANX</li>
                                </ul>

                            </li>
                            <li>For USDT TRC-20 (TRON) payments please use the following wallet address:
                                <ul className='ml-3' style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>TL2X2Buo89FuFVfq3Jrp7ySd897wp3uuvf</li>
                                    <CopyToClipboardButton text="TL2X2Buo89FuFVfq3Jrp7ySd897wp3uuvf" tokenName={"USDT TRC-20"} />
                                </ul>
                            </li>

                            <li>For BTC payments please use the following wallet address:
                                <ul className='ml-3' style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>bc1qk3qfswhpr9qr8knnludecpvd0pmku2mzzun5n3</li>
                                    <CopyToClipboardButton text="bc1qk3qfswhpr9qr8knnludecpvd0pmku2mzzun5n3" tokenName={"BTC"} />
                                </ul>
                            </li>

                            <li>For USDT ERC-20 payments please use the following wallet address:
                                <ul className='ml-3' style={{ listStyleType: "disc" }}>
                                    <li style={{ listStyleType: "disc" }}>0xea56bd69871667FF23B89053AAB11A6E1Df5a652</li>
                                    <CopyToClipboardButton text="0xea56bd69871667FF23B89053AAB11A6E1Df5a652" tokenName={"USDT ERC-20"} />
                                </ul>
                            </li>
                            <li>{countryCurrency} deposits will remain pending until amount is reflected in the bank account of C1 Wealth Management.</li>
                            <li>Approvals may take up to 24 hours</li>

                            <li>There are no deposit fees applicable and “over the counter” have fixed fees structures</li>


                        </ol>

                    </div>
                    <div className='m-auto text-center'><button onClick={openTransactionModal} className='c1-primary-btn mt-5 text-center deposit-button'>Wallet Deposit</button></div>
                    <div className='m-auto text-center'><button onClick={openLoanTransactionModal} className='c1-primary-btn mt-5 text-center deposit-button'>Loan Wallet Deposit</button></div>

                </div>



            </div>



        </div>
    )
}

export default Deposit